:root {
  --navbar-height: 50px;
}

body {
  margin: 0px !important;
}

.navbar-brand {
  padding: 0;
  margin: 0;
}

/* CAROUSEL */
.carousel-item {
  height: 100vh;
  overflow: hidden;
}

.carousel-item-container {
  position: relative;
  height: 100%;
  width: 100%;
}

.carousel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.darken {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

@media (max-width: 576px) {
  .carousel-item {
    height: 40vh;
    margin-top: var(--navbar-height);
  }

  .carousel-item-container {
    height: 100%;
  }

  .carousel-image {
    object-position: center;
  }
}

.yellow-gradient {
  /* Created with https://www.css-gradient.com */
  background: #7586ca;
  background: -webkit-linear-gradient(top left, #7586ca, #147fdb);
  background: -moz-linear-gradient(top left, #7586ca, #147fdb);
  background: linear-gradient(to bottom right, #7586ca, #147fdb);
}

.service-gradient {
  background-color: #8ec5fc;
  background-image: linear-gradient(62deg, #8ec5fc 0%, #e0c3fc 100%);
}

.very-small {
  font-size: 10px;
}

.img-200 {
  height: 200px;
  width: 200px;
}
